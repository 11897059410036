#footer_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #00aaff;
  height: 35px;
}

#footer_box,
#footer_a {
  color: white;
  text-decoration: none;
  padding-right: 0.5rem;
  font-size: 0.9rem;
}
