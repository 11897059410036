#navBar {
  background-color: #00aaff;
  border-right: 1px solid black;
  padding: 1rem;
  height: 100dvh;
  min-width: 250px;
}

#navBar a,
#navBar h6 {
  text-decoration: none;
  color: white;
  font: 500 20px/32px Roboto, sans-serif;
  padding: 0.75rem 0;
  cursor: pointer;
}

#navBar a:not(:first-child) {
  min-width: 200px;
  text-align: end;
}

#navBar h6 {
  color: blue;
}

#navBar a:hover,
#navBar h6:hover,
#navBar a:hover > * {
  transition: all 0.4s ease;
  color: black;
}

#navBar a.activeLink,
#navBar a.activeLink > * {
  color: black;
}
