* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.eucBYu {
  height: 100%;
  min-width: 30vw;
  font-size: 12px;
}
